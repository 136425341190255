import React from "react";

export default class Clash extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isLoaded: false,
      clash: ""
    };
    this.scoreLoaded = this.scoreLoaded.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.clash !== prevProps.clash) {
      this.scoreLoaded(this.props.clash);
    }
  }

  scoreLoaded(clash) {
    this.setState({
      isLoaded: true,
      clash: clash
    });
  }


  render() {
    return (
      <div>
        <h5 id="luckyDates">Unlucky Dates in the Week</h5>
        <div class="saju">{
          this.state.isLoaded
          ? this.props.clash["description"] : ""}
        </div>
        <div class="dates">{
          this.state.isLoaded
          ? this.props.clash["dates"] : ""}
        </div>
      </div>
    );
  }
}
