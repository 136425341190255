import React from "react";
import StarsModel from './starsModel';
import Saju from './saju';

export default class Stars extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      saju: []
    };
    this.scoreLoaded = this.scoreLoaded.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.saju !== prevProps.saju) {
      this.scoreLoaded(this.props.saju);
    }
  }

  scoreLoaded(saju) {
    this.setState({
      isLoaded: true,
      saju: saju
    });
  }

  render() {
    return (
      <div class="sajuContainer">
        <div class="sajuSection">
          <h4 id="tryItOut" class="career">Career and Wealth</h4>
          <span>
            <StarsModel
              rating={
                this.state.isLoaded
                  ? Number(this.state.saju["career"]["score"])
                  : 0
              }
              dimensions="20px"
            />
          </span>
          <Saju fortune={
            this.state.isLoaded
            ? this.state.saju["career"]["description"] : []
          } dates={
            this.state.isLoaded
            ? this.state.saju["career"]["bestDates"] : []
          }
          />
        </div>

        <div class="sajuSection">
          <h4 id="tryItOut" class="love">Relationships and Love</h4>
          <span>
            <StarsModel
              rating={
                this.state.isLoaded
                  ? Number(this.state.saju["love"]["score"])
                  : 0
              }
              dimensions="20px"
            />
          </span>
          <Saju fortune={
            this.state.isLoaded
            ? this.state.saju["love"]["description"] : []
          } dates={
            this.state.isLoaded
            ? this.state.saju["love"]["bestDates"] : []
          }
          />
        </div>

        <div class="sajuSection">
          <h4 id="tryItOut" class="success">Path to Success</h4>
          <span>
            <StarsModel
              rating={
                this.state.isLoaded
                  ? Number(this.state.saju["success"]["score"])
                  : 0
              }
              dimensions="20px"
            />
          </span>
          <Saju fortune={
            this.state.isLoaded
            ? this.state.saju["success"]["description"] : []
          } dates={
            this.state.isLoaded
            ? this.state.saju["success"]["bestDates"] : []
          }
          />
        </div>
      </div>
    );
  }
}