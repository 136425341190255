import axios from "axios";
import React, { Component } from "react";
import Animal from "./animal"
import Stars from "./stars";
import Clash from "./clash";
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";


const materialTheme = createMuiTheme({
  typography: {
    htmlFontSize: 10,
    fontFamily: 'Lexend'
  },
  palette: {
    primary: {
      main: '#900603',
    }
  }
});

class TryItOut extends Component {
  constructor() {
    super();
    this.handleDateChange = this.handleDateChange.bind(this)
    this.onChangeValue = this.onChangeValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      selectedDate: new Date('2000-01-01T00:00'),
      gender: '',
      defaultSaju: {},
      saju: {},
      showSaju: false
    }
  }

  handleDateChange(date) {
    this.setState({
      selectedDate: date
    })
  }

  onChangeValue(event) {
    this.setState({
      gender: event.target.value
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    let params = {
      params: {
        birthdate: this.state.selectedDate,
        gender: this.state.gender
      }
    }
    axios
      .get("/saju", params)
      .then(({ data }) => {
        this.setState({
          saju: data,
          showSaju: true
        });
      })
      .catch(error => {
        console.error(error)
      })
  }

  render() {
    return (
      <ThemeProvider theme={materialTheme}>
        <div id="tryItOut" className="text-center">
          <div className="container">
            <div className="section-title">
              <h2>Find your true self & guide your future</h2>
              <p></p>
              <p>Enter your birthday:</p>
              <p>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker label="date required" format="MM/dd/yyyy" minDate={new Date('1920-01-01')} maxDate={new Date('2030-12-31')} value={this.state.selectedDate} onChange={this.handleDateChange} />
                  {/* <KeyboardTimePicker label="time optional" value={this.state.selectedDate} onChange={this.handleDateChange} /> */}
                </MuiPickersUtilsProvider>
              </p>
              <p>And gender:</p>
              <div className="radio-btn" onChange={this.onChangeValue}>
                <label id="female"><input type="radio" value="Female" name="gender" /> Female </label>
                <label id="male"><input className="radio-btn-desc" type="radio" value="Male" name="gender" /> Male </label>
              </div>
            </div>
            <button onClick={this.handleSubmit} type="submit" className="btn btn-custom btn-lg">
              Submit
            </button>
            <h3 id="sajuTitle">Your Month's Fortune</h3>
            <Stars saju={this.state.saju} />
            <Clash clash={this.state.saju["clash"]} />
            <Animal animal={this.state.saju["animal"]} />
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default TryItOut;
