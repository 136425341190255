import React from "react";

export default class Saju extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div>
        <div class="saju">{this.props.fortune}</div>
        <h5 id="luckyDates">Lucky Dates in the Week</h5>
        <div class="dates">{this.props.dates}</div>
      </div>
    );
  }
}
