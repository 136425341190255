import React, { Component } from "react";

export class Product extends Component {

  render() {
    return (
      <div id="enhanceYourLuck" className="text-center">
        <div className="container">
          <div className="col-md-8 col-md-offset-2 section-title">
            <h2>Enhance Your Luck</h2>
            <p>
              We are designing products with colors and images to enhance your luck based on your birthday. We'll include tips on where to place them in your home to benefit from fengshui. Click below to join our signup list.
            </p>
            <a id="interest" href="#contact" className="btn btn-custom btn-lg">
              Interested!
            </a>
          </div>
          <div className="row">
            <div className="portfolio-items">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                      <div className="hover-text">
                        <h4>Sample Spring Blossoms</h4>
                        <p>Direction: South</p> 
                        <applause-button style={{width: '58px', height: '58px', margin: '20px'}}/>
                      </div>
                      <img
                        src="img/product/bloom.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <div className="hover-text">
                      <h4>Sample Koi Fish</h4>
                      <p>Direction: Northeast</p>
                      <applause-button style={{width: '58px', height: '58px', margin: '20px'}}/>
                    </div>
                    <img
                      src="img/product/koi.jpg"
                      className="img-responsive"
                      alt="Project Title"
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <div className="hover-text">
                      <h4>Sample Bamboo Forest</h4>
                      <p>Direction: East</p> 
                      <applause-button style={{width: '58px', height: '58px', margin: '20px'}}/>
                    </div>
                    <img
                      src="img/product/bamboo.jpg"
                      className="img-responsive"
                      alt="Project Title"
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <div className="hover-text">
                      <h4>Sample Tiger T-Shirt</h4>
                      <p>Direction: East</p> 
                      <applause-button style={{width: '58px', height: '58px', margin: '20px'}}/>
                    </div>
                    <img
                      src="img/product/tiger.png"
                      className="img-responsive"
                      alt="Project Title"
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <div className="hover-text">
                      <h4>Sample Koi Fish Painting</h4>
                      <p>Direction: North</p> 
                      <applause-button style={{width: '58px', height: '58px', margin: '20px'}}/>
                    </div>
                    <img
                      src="img/product/picture-livingroom.png"
                      className="img-responsive"
                      alt="Project Title"
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <div className="hover-text">
                      <h4>Sample Pasture on Phone Case</h4>
                      <p>Direction: West</p> 
                      <applause-button style={{width: '58px', height: '58px', margin: '20px'}}/>
                    </div>
                    <img
                      src="img/product/iphone-case.png"
                      className="img-responsive"
                      alt="Project Title"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Product;
