import React from "react";

export default class Animal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isLoaded: false,
      animal: ""
    };
    this.scoreLoaded = this.scoreLoaded.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.animal !== prevProps.animal) {
      this.scoreLoaded(this.props.animal);
    }
  }

  scoreLoaded(animal) {
    this.setState({
      isLoaded: true,
      animal: animal
    });
  }


  render() {
    return (
      <div class="animal">
        <h3 id="animal">Your Spirit Animal</h3>
        <div id="name">{
          this.state.isLoaded
          ? this.props.animal["name"] : ""}
        </div>
        <div id="description">{
          this.state.isLoaded
          ? this.props.animal["description"] : ""}
        </div>
      </div>
    );
  }
}
